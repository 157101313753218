<template>
  <div>
    <Header ref="header"/>

    <div class="container">
      <div class="w w1390">
        <div class="filter">
          <div class="filter-wrap">
            <div class="filter-sort">
              <a href="javascript:void(0);" v-on:click="orderPrice">
                价格<i class="iconfont" :class="priceIcon"></i>
              </a>
            </div>
            <div class="filter-range">
            </div>
            <div class="filter-right">
              <div class="styles">
                <ul class="items">
                  <li :class="['item',{'current':listType}]" v-on:click="listType = !listType" v-show="listType">
                    <a href="javascript:void(0)" title="大图模式"><span class="iconfont icon-switch-grid"></span>大图</a>
                  </li>
                  <li :class="['item',{'current':!listType}]" v-on:click="listType = !listType" v-show="!listType">
                    <a href="javascript:void(0)" title="小图模式"><span class="iconfont icon-switch-list"></span>小图</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="g-view w">
          <div class="goods-list" v-loading="SearchLoading" element-loading-text="拼命加载中......">
            <div class="tip">
              <p>温馨提示：部分商品包装更换频繁，如货品与图片不完全一致，请以收到的商品实物为准。</p>
            </div>
            <div class="gl-warp gl-warp-large" :style="{display:listType ? 'block' : 'none'}" v-if="promotionDetailList && promotionDetailList.length > 0">
              <div class="goods-list-warp" v-if="carts.length > 0">
                <ul>
                  <li class="gl-item" v-for="(good,index) in promotionDetailList" v-bind:key="good.id">
                    <div class="gl-i-wrap">
                      <div class="p-img">
                        <img :src="getCoverImage(good.images)">
                      </div>
                      <div class="p-lie">
                        <div class="p-price"><em>¥</em>{{good.price}}</div>
                        <div class="original-price" v-if="good.marketPrice"><em>¥</em>{{good.marketPrice}}</div>
                      </div>
                      <div class="p-name">
                        {{good.name}}<template v-if="good.brandName">({{good.brandName}})</template>
                      </div>
                      <div class="sepc">
                        <p v-for="(para,index) in good.paras" v-bind:key="index">{{para.name}}：{{para.value}}</p>
                      </div>
                      <div class="brief" v-if="good.subName">
                        [促]
                        <p v-for='(b,i) in good.subName.split("$$")' v-bind:key="i">
                          <template>{{b}}</template>
                        </p>
                      </div>
                      <div class="p-operate">
                        <amount-box v-model="carts[index].addNum" :defaultVal="carts[index].addNum" :max="99999" :min="0"></amount-box>
                        <a href="javascript:void(0);" v-on:click="addCart(index,good)" class="addcart">
                          <i class="iconfont icon-carts"></i>加入购物车
                        </a>
                        <div style="clear: both"/>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="gl-warp gl-warp-samll" :style="{display:!listType?'block':'none'}" v-if="promotionDetailList && promotionDetailList.length > 0">
              <div class="goods-list-warp" v-if="carts.length > 0">
                <ul>
                  <li :class="[index%2==0?'gl-h-item':'gl-h-item item_bg']" class="" v-for="(good,index) in promotionDetailList" v-bind:key="good.id">
                    <div class="gl-i-wrap">
                      <div class="col col-1">
                        <div class="p-img">
                          <img :src="getCoverImage(good.images)">
                        </div>
                        <div class="p-right">
                          <div class="p-name">
                            {{good.name}} <template v-if="good.brandName">({{good.brandName}})</template>
                          </div>
                        </div>
                      </div>
                      <div class="col col-2">
                        <div class="p-lie">
                          <ul>
                            <li v-for="(para,index) in good.paras" v-bind:key="index">{{para.name}}：{{para.value}}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="col col-3">
                        <div class="p-price">
                          <div class="shop-price">
                            <em>¥</em>{{good.price}}
                          </div>
                        </div>
                      </div>
                      <div class="col col-3">
                        <div class="p-price">
                          <div>
                            {{good.stock}}
                          </div>
                        </div>
                      </div>
                      <div class="col col-4">
                        <div class="p-operate">
                          <number-box :defaultVal="carts[index].addNum" v-model="carts[index].addNum" @on-change="onParamChange(index, $event)" :max="good.stock" :min="0" :step="1" @keyup.enter.native="addCart(index,good)"></number-box>
                          <el-badge :value="carts[index].num" class="item" v-if="carts[index].num>0">
                            <a href="javascript:void(0);" v-on:click="addCart(index,good)" class="addcart">
                              <i class="iconfont icon-carts"></i>加入购物车
                            </a>
                          </el-badge>
                          <a href="javascript:void(0);" v-on:click="addCart(index,good)" class="addcart" v-if="carts[index].num<=0">
                            <i class="iconfont icon-carts"></i>加入购物车
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="no_records" v-if="promotionDetailList && promotionDetailList.length==0">
              <i class="no_icon_two"></i>
              <div class="no_info no_info_line">
                <h3>抱歉，没有找到符合条件的数据</h3>
                <div class="no_btn">
                  <router-link :to="{path:'/'}" class="btn sc-redBg-btn">
                    返回首页
                  </router-link>
                </div>
              </div>
            </div>

            <div class="clear"></div>
          </div>
          <div class="goods-spread">
            <a href="javascript:void(0);" class="g-stop">
              <i class="iconfont icon-right"></i>
            </a>
            <div class="gs-warp">
              <div class="gs-tit">推广商品</div>
              <ul class="gs-list">
                <li class="opacity_img" v-for="(good) in extensions" v-bind:key="good.id">
                  <div class="">
                    <div class="p-img">
                      <router-link :to="{path:'goods',query:{id:good.goodsId}}" target="_blank" tag="a">
                        <img :src="getCoverImage(good.images)" width="190" height="190">
                      </router-link>
                    </div>
                    <div class="p-price">¥{{good.price}}</div>
                    <div class="p-name">
                      <router-link :to="{path:'goods',query:{id:good.goodsId}}" target="_blank" tag="a">
                        {{good.name}}
                      </router-link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <guess-love :count="7"></guess-love>
      </div>
    </div>

    <Footer/>
  </div>
</template>
<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
import GuessLove from "@/components/GuessLove/Index.vue";
import AmountBox from "@/components/AmountBox/Index.vue";
import NumberBox from "@/components/NumberBox/Index.vue";
import {getList} from "@/api/mall/marketing/promotionDetail";
import {getInfo} from "@/api/mall/marketing/Promotion";
import {cartCreate, getGoodsSearch} from "@/api/good";
import {getCartList} from "@/api/user";
export default {
  name: "marketingPromotion",
  data() {
    return {
      params: {
        promotionId: '',

        currentPage: 1,
        pageSize: 20,
        totalRecord: 0
      },

      promotion: {},
      promotionDetailList: [],

      priceOrder:'price asc',
      priceIcon: 'icon-arrow-up',
      listType: false,
      sidx: 'Id',
      sord: 'desc',

      SearchLoading:false,

      extensions: [],      //推广

      carts : [],
      cartList: []
    }
  },
  components: {
    NumberBox, AmountBox, GuessLove,
    Header,
    Footer
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  created() {
    this.params.promotionId = this.$route.query.id

    this.getInfo()
    this.getList()

    this.getExtensions()

  },
  methods:{
    getInfo(){
      getInfo(this.params.promotionId).then(res => {
        this.promotion = res.data
      });
    },
    getList(){
      this.carts = []
      this.cartList = []

      getList({
        promotionId: this.params.promotionId
      }).then(res => {
        this.promotionDetailList = res.data.list

        const goodsIds = res.data.list.map((item) => {
          return item.goodsId;
        });

        //获取当页购物车信息，如果获取全部代价有点大（比如有些客户购物出列表300+）
        getCartList({goodsIds:goodsIds.join(',')}).then(res=>{
          console.log('############')
          console.log(res)
          console.log('############')

          this.cartList = res.data.normal
          this.promotionDetailList.forEach((i)=>{
            if(i.paramsData != null){
              i.paras = JSON.parse(i.paramsData.replace(/[\r|\n|\t]/g,''));
            }else{
              i.paras = []
            }

            let has = this.cartList.filter(item=>item.goodsId == i.goodsId)
            if(has.length>0){
              i.num = has[0].num
              i.addNum = 0
              this.carts.push({num: has[0].num,addNum:0});
            }else{
              i.num = 0
              i.addNum = 0
              this.carts.push({num: 0,addNum:0});
            }
          })
        })

      });
    },
    orderPrice(){
      if(this.priceOrder=='price asc'){
        this.priceOrder = 'price desc'
        this.sord = 'desc'
        this.sidx = 'Price'
        this.priceIcon = 'icon-arrow-down'
        this.getGoods()
      }else{
        this.priceOrder = 'price asc'
        this.sord = 'asc'
        this.sidx = 'Price'
        this.priceIcon = 'icon-arrow-up'
        this.getGoods()
      }
    },
    getCoverImage(images){
      let json = JSON.parse(images)
      return json[0].attachmentId == '' ? this.staticUrl + '/app/no-pic.png' : this.staticUrl + '/' + json[0].imageUrl
    },
    getExtensions(){
      getGoodsSearch({
        currentPage: 1,
        pageSize: 6,
        sidx: 'newid()',
      }).then(res => {
        this.extensions = res.data.list
      });
    },
    onParamChange(index, val){
      this.carts[index].addNum = val
    },
    addCart(index,goods){
      if(this.carts[index].addNum <= 0){
        this.$message({
          message: '购物车数量必须大于0',
          type: 'warning'
        })
      }else{
        let _this = this

        cartCreate({
          goodsId: goods.goodsId,
          goodsNo: goods.goodsNo,
          qty: this.carts[index].addNum,
          price: goods.price,
          signId: 2,
          //skuId: this.showSelect.Id,
          specInfo: goods.specs,
          source: 'promotion',
          objectId: this.promotion.id,
          objectName: this.promotion.name
        }).then((res) => {
          // if (res.code == 400){
          // 	_this.$h.toast(res.msg);
          // }else{
          //_this.$store.commit('cart/setCartAdd', true);
          //_this.$store.commit('cart/cartNum', true);
          //_this.$h.toast('加入购物车成功');

          if(res!=undefined){
            _this.$message({
              message: '加入购物车成功',
              type: 'success'
            });

            _this.carts[index].num = this.carts[index].num + this.carts[index].addNum;
            _this.carts[index].addNum = 0;

            _this.$store.dispatch('GetCartSum');
          }
        }).catch(err => {
          console.log(err);
          // _this.$h.toast(err);
          // _this.$toError(err);
        });
      }
    },
  }
}
</script>
<style>
@import "../../../assets/style/select.css";

.p-name .el-button{
  padding: 0;
}

.gl-h-item .gl-i-wrap .col-1{ width:16%; margin-right:2%;}
.gl-h-item .gl-i-wrap .col-2{ width:40%; margin-right:1%;}
.gl-h-item .gl-i-wrap .col-3{ width:5%; margin-right:2%;}
.gl-h-item .gl-i-wrap .col-4{ width:27%; margin-right:0;}
.col .p-lie{ margin-top:0;}
.gl-i-wrap .p-name {margin-top: 0;}

@media screen and (min-width: 1230px) and (max-width: 1450px){
  .gl-h-item .gl-i-wrap .col-1{ width:16% !important; margin-right:2% !important;}
  .gl-h-item .gl-i-wrap .col-2{ width:40% !important;; margin-right:1% !important;;}
  .gl-h-item .gl-i-wrap .col-3{ width:5% !important;; margin-right:2% !important;;}
  .gl-h-item .gl-i-wrap .col-4{ width:27% !important;; margin-right:0 !important;;}
}

.p-num,.p-name{
  color: #000 !important;
  font-size: 13px;
}
.p-num{
  display: block !important;
}

.col-2 ul li{
  width: 49%;
  float: left;
  color: #000;
  overflow: hidden;
  white-space: nowrap;
}

.col .p-name{
  height: 60px;
}
.col .p-name a{
  height: 60px;
  white-space: break-spaces;
}

.counter-component{float: left;padding-right: 5px;}
.counter-component input{width: 58px;}
.counter-show input{
  border-top: 1px dotted #dbdbdb !important;
  border-bottom: 1px dotted #dbdbdb !important;
}
.counter-btn {
  border: 1px dotted #dbdbdb !important;
}

.gl-i-wrap .p-operate{overflow: visible;}
.gl-i-wrap .p-operate {padding-right: 30px;}
.el-badge__content.is-fixed{z-index: 9999;}

.el-pagination{font-weight: normal;}
.el-pager .number:hover{color: #f42424 !important;}
.el-pager .active{background-color: #f42424 !important;color: #fff;}
.el-pager .active:hover{color: #fff !important;}

.brief{clear: both;color: blue;font-weight: bold;}

.original-price{text-decoration:none}
.gl-warp-large .original-price{padding-left: 48px;font-size: 16px;}

.p-name{
  font-size: 14px;
  font-weight: bold;
}

.tip{
  background-color: #e6a23c;
  padding: 12px 5px 12px;
  color: #fff;
  margin-bottom: 8px;
}

.filter{
  margin-top: 12px;
}
</style>